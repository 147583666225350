<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10 md:hidden" @close="open = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="-translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-neutral-800 py-6 shadow-xl">
                  <div class="px-4 sm:px-6 sticky top-0 z-50">
                    <div class="ml-5 flex items-end justify-end float-right">
                      <div class="ml-3 inline-flex h-full items-center float-right">
                        <button type="button" class="rounded-md bg-transparent text-neutral-300 hover:text-white align-middle self-center my-auto focus:outline-none focus:ring-none" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <ChevronDoubleLeftIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 mb-12 flex-1 px-4 sm:px-6 text-white">
                    <!-- Replace with your content -->
                    <div class="absolute inset-0 px-4 sm:px-6 -mt-4">
                      <Category v-for="entry in structureEntries" :label="entry" :libraries="originalStructure[entry]" />
                      <div class="h-12"/>
                    </div>
                    <!-- /End replace -->
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <nav class="sticky top-0 z-10 flex bg-neutral-900 drop-shadow-xl md:hidden px-1.5 h-10" aria-label="Breadcrumb">
    <ol role="list" class="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
      <li class="flex">
        <div id="sidebarToggle" @click="open = true" class="flex items-center cursor-pointer">
          <a class="text-neutral-100 hover:text-white">
            <ChevronDoubleRightIcon class="h-5 w-5 p-0.5 flex-shrink-0" aria-hidden="true" />
          </a>
        </div>
      </li>
      <li v-for="page in getBreadcrumbData()" :key="page" class="flex">
        <div class="flex items-center">
          <svg class="h-full w-6 flex-shrink-0 text-neutral-800" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <span class="ml-4 text-sm font-medium text-neutral-200">{{ page }}</span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon, ChevronDoubleLeftIcon } from '@heroicons/vue/outline'

import { ChevronDoubleRightIcon } from '@heroicons/vue/solid';

import Category from './Sidebar/Category.vue'
import SearchButton from './Header/SearchButton.vue'

import _ from 'lodash';

export default {
  components: {
    Category,
    ChevronDoubleRightIcon,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    SearchButton,
    XIcon,
    ChevronDoubleLeftIcon
  },
  methods: {
    getBreadcrumbData() {
      let libraryId = this.$route.path.split('/').filter((item) => item);
      if (Object.entries(this.structure).length > 0) {
        return this.structure[libraryId[0]];
      }
    }
  },
  data() {
      return {
          originalStructure: {},
          structureEntries: [],
          structure: {},
          open: false
      }
  },
  watch:{
    $route (to, from){
        if (to.hash) {
          this.open = false;
        }
    }
  },
  created() {
      // Grab category, library, and keyword labels from server
      fetch("https://px-actions.herokuapp.com/structure", { headers: { "x-api-key": "c3902840-8073-4518-928f-c513391dcd8f" } })
        .then(response => response.json())
        .then(data => {
          if (Object.entries(data).length > 0) {
            this.originalStructure = data;
            this.structureEntries = ['Common', 'Robotic', 'Data Transformation', 'Integrations', 'Interactive', 'Artificial Intelligence'];
            Object.entries(data).forEach(([key, value]) => {
              Object.entries(value).forEach(([libKey, libValue]) => {
                this.structure[libValue.id] = [key, libKey];
              });
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
  }
}
</script>