<template>
  <main class="bg-neutral-100 text-px-grey md:col-span-5 col-span-7 overflow-y-scroll overflow-x-hidden h-auto">
    <div v-if="library" class="prose lg:max-w-4xl px-12 mx-auto mb-8">
      <div>
        <h3 class="text-4xl font-medium">{{ library.label }}</h3>
        <!-- <div v-html="library.doc_html"></div> -->
      </div>
      <div class="" :class="{ 'pb-12': '#' + kwd.label === $route.hash }" v-for="kwd in library.keywords.sort((a, b)=>a.label.localeCompare(b.label))">
        <div class="h-16 md:h-1" :id="kwd.label" />
        <h2 class="border-t pt-12 -mb-1 border-neutral-300">{{ kwd.label }}</h2>
        <div class="" v-html="cleanedUp(kwd.doc_html)"></div>
        <div v-if="kwd.args.length > 0">
          <h3 class="font-medium text-2xl">Inputs</h3>
          <table>
            <tbody>
              <tr v-for="arg in kwd.args">
                <div class="inline-flex flex-wrap my-2" v-if="arg.name">
                  <td style="width:12rem;" class="font-semibold pr-3 pl-0 py-0">{{ arg.name }}{{ !arg.required ? ` (optional)` : "" }}</td>
                  <td class="pr-3 py-0 text-neutral-400" style="width:10rem;">{{ cleanTypes(arg.types) }}</td>
                  <td class="px-0 py-0" v-html="cleanDescription(cleanedUp(arg.doc))"></td>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="kwd.returns?.type || kwd.returns?.doc">
          <h3 class="font-medium text-2xl">Outputs</h3>
          <div v-html="cleanDescription(cleanedUp(kwd.returns.doc))" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import _ from 'lodash';
import NProgress from 'nprogress';
import Breadcrumb from './Breadcrumb.vue';

export default {
  name: 'Content',
  props: {
    libraries: Object
  },
  components: {
    Breadcrumb
  },
  data() {
    return {
      library: null
    }
  },
  methods: {
    cleanTypes(types) {
      if (types.length === 1 && types[0] == "any") {
        return "Any";
      } else {
        return types.filter(type => !["any"].includes(type)).map(type => { 
          switch (type.toLowerCase()) {
            case "dict":
              return "Dictionary";
            case "float":
              return "Number";
            case "int":
              return "Number";
            case "value":
              return "Option";
            case "literal":
              return "Option";
            case "multiliteral":
              return "Option(s)";
            case "multivariable":
              return "Variable(s)";
            case "typeddict":
              return "Dictionary";
            case "integer":
              return "Number";
            case "json":
              return "JSON";
            default:
              return _.startCase(type);
          }
        }).join(', ');
      }
    },
    cleanDescription(description) {
      // capitalize first letter in the description sentence and add a period at the end if there isn't one
      return description.charAt(0).toUpperCase() + description.slice(1) + (description.slice(-1) === "." ? "" : ".");
    },
    cleanedUp(doc) {
      // Wrap text that is in backticks in <code> blocks
      doc = this.backticksToCode(doc)

      // Remove style tag
      doc = doc.replaceAll("<link rel='stylesheet' href='css/keyword.css'>", '');

      // Remove arguments table
      doc = doc.replace(/<h3>Arguments<\/h3>.*/s, '');

      return doc;
    },
    backticksToCode(text) {
      return text.replace(/```([^`]*)```/g, '<code>$1</code>').replace(/``([^`]*)``/g, '<code>$1</code>').replace(/`([^`]*)`/g, '<code>$1</code>');
    }
  },
  // TODO: Content and Sidebar work off completely different data, which can lead to desyncs if developers are careless. It may be good to change that.
  beforeRouteEnter(to, from, next) {
    NProgress.start()
    // Only triggers when first loading in to the page
    fetch(`https://px-actions.herokuapp.com/fetch/${to.params.library}`, { headers: { "x-api-key": "c3902840-8073-4518-928f-c513391dcd8f" } })
      .then(response => response.json())
      .then(data => {
        next(vm => {
          vm.libraries[to.params.library] = data;
          vm.library = data;
          if (NProgress.isStarted()) NProgress.done();
        });
      })
      .catch(error => {
        console.error(error);
        if (NProgress.isStarted()) NProgress.done();
      });
  },
  beforeRouteUpdate(to, from) {
    if (to.params.library !== from.params.library) {
      NProgress.start()
    }
    // If we already have the library, don't bother fetching it again
    if (Object.keys(this.libraries).includes(to.params.library)) {
      this.library = this.libraries[to.params.library];
      if (NProgress.isStarted()) NProgress.done();
      return;
    }
    // Grab content for the target page if we haven't already got it.
    fetch(`https://px-actions.herokuapp.com/fetch/${to.params.library}`, { headers: { "x-api-key": "c3902840-8073-4518-928f-c513391dcd8f" } })
      .then(response => response.json())
      .then(data => {
        this.libraries[to.params.library] = data;
        this.library = data;
        if (NProgress.isStarted()) NProgress.done();
      })
      .catch(error => {
        console.error(error);
        if (NProgress.isStarted()) NProgress.done();
      });
  },
  watch: {
    library: function (to, from) {
      if (to && from && to.id === from.id) return;
      // Scroll to anchor when data loads after page load or navigation
      // This is *not* responsible for scrolling to other keywords in the same library
      this.$nextTick(() => {
        if (this.$route.hash) {
          let anchor = document.getElementById(this.$route.hash.substring(1));
          anchor && anchor.scrollIntoView();
        } else {
          // FIXME: Not triggering consistently, probably due to load times.
          this.$el.scrollTo({ top: 0 });
        }
      })
    }
  }
}

</script>

<style scoped>
/* Styles for v-html elements that cannot be styled with tailwind */
.prose :where(p):not(:where([class~=not-prose] *)) {
    overflow-wrap: break-word;
    white-space: initial;
}

.prose :where(li):not(:where([class~=not-prose] *)) {
    margin-top: 0em;
    margin-bottom: 0em;
}

.prose :where(table):not(:where([class~=not-prose] *)) {
    margin-top: 0em;
    margin-bottom: 0em;
}

div>>>code {
  background-color: #ddd;
  font-weight: normal;
  padding: 0.1rem;
  border-radius: 0.25rem;
}

div>>>code::after,
div>>>code::before {
  content: "";
}

div>>>.optional {
  background: #ddd;
}

div>>>pre {
  background-color: #ddd;
  color: inherit;
}
p {
  margin-top: 1rem;
  margin-bottom: 0;
}
</style>
