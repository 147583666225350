<template>
  <div class="mt-6">
    <div class="mx-auto mb-16">
      <div class="divide-y-2 divide-gray-200">
        <dl class="space-y-4">
          <Disclosure as="div" v-for="pattern in patterns" :key="pattern.name" :defaultOpen="true" class=" bg-neutral-100 border-neutral-200 border-4 rounded-md p-4 hover:shadow duration-200" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-400 ">
                <div class="flex flex-col">
                <span class="font-medium text-xl text-gray-600">{{ pattern.name }}</span>
                <span class="text-sm font-medium text-gray-500 mt-1">{{ pattern.description }}</span>
                </div>
                <span class="ml-6 flex h-7 items-center">
                  <ChevronLeftIcon :class="[open ? '-rotate-90' : 'rotate-0', 'h-6 w-6 transform transition-all duration-200']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel @click="goToAction(action)" v-for="(action, idx) in pattern.actions" as="dd" :style="'border-color: ' + getCategoryMetadata(action.category)['color']" :class="['pr-4 cursor-pointer bg-white rounded-md md:border-4 border-2 hover:opacity-100 hover:shadow-xl duration-200', (idx == 0 ? 'mt-6' : 'md:mt-4 mt-2') ]">
              <div class="flex flex-row h-full justify-between">
              <div class="inline-flex w-full h-full">
                <div :style="'background-color: ' + getCategoryMetadata(action.category)['color']" class="md:w-20 md:h-20 md:block hidden">
                  <img :src="getCategoryMetadata(action.category)['icon']" :class="['text-white self-center align-middle md:p-4 p-2']" />
                </div>
                <div class="align-middle self-center h-full md:my-auto pb-px ml-3 md:ml-4 my-2">
                  <p class="text-sm md:text-xl text-gray-500" :style="'color: ' + getCategoryMetadata(action.category)['color']">{{ action.category }} → {{ action.library }}</p>
                  <p class="text-sm md:text-xl font-semibold" :style="'color: ' + getCategoryMetadata(action.category)['color']">{{ action.name }}</p>
                </div>
              </div>
                <div class="align-middle self-center h-full my-auto pb-px right-0">
                  <ExternalLinkIcon class="h-6 w-6 text-neutral-300" aria-hidden="true" />
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronLeftIcon, ExternalLinkIcon } from '@heroicons/vue/solid'

import meta from './Sidebar/meta';

const patterns = [
  {
    name: "Logging in to a website",
    description: "Open the browser, navigate to websites, type in text, and click buttons.",
    actions: [
      {
        category: "Robotic",
        library: "Browser",
        name: "Open A New Page",
        href: "/prefix.PBrowser#Open%20A%20New%20Page"
      },
      {
        category: "Robotic",
        library: "Browser",
        name: "Navigate To Page",
        href: "/prefix.PBrowser#Navigate%20To%20Page"
      },
      {
        category: "Robotic",
        library: "Browser",
        name: "Type Text On Element",
        href: "/prefix.PBrowser#Type%20Text%20On%20Element"
      },
      {
        category: "Robotic",
        library: "Browser",
        name: "Click On Element",
        href: "/prefix.PBrowser#Click%20On%20Element"
      },
    ]
  },
  {
    name: "Extracting data from a website",
    description: "Get to a particular website, search for something, wait for results, and pull out data.",
    actions: [
      {
        category: "Robotic",
        library: "Browser",
        name: "Scrape Data",
        href: "/prefix.PBrowser#Scrape%20Data"
      },
      {
        category: "Robotic",
        library: "Browser",
        name: "Get Elements",
        href: "/prefix.PBrowser#Get%20Elements"
      },
      {
        category: "Robotic",
        library: "Browser",
        name: "Get Element Attributes",
        href: "/prefix.PBrowser#Get%20Element%20Attributes"
      },
      {
        category: "Robotic",
        library: "Browser",
        name: "Wait Time",
        href: "/prefix.PBrowser#Wait%20Time"
      },
      {
        category: "Common",
        library: "Logic",
        name: "Sleep",
        href: "/prefix.Common#Sleep"
      },
    ]
  },
  {
    name: "Calling an API",
    description: "Connect to internal services or applications Prefix does not have direct integrations for.",
    actions: [
      {
        category: "Integrations",
        library: "Requests",
        name: "GET",
        href: "/prefix.Requests#Get"
      },
      {
        category: "Integrations",
        library: "Requests",
        name: "POST",
        href: "/prefix.Requests#Post"
      },
    ]
  },
  {
    name: "Getting text from a document",
    description: "Pull out text from a PDF or send it to a service to get text from an image.",
    actions: [
      {
        category: "Robotic",
        library: "PDF",
        name: "Get Text From PDF",
        href: "/prefix.PDF#Get%20Text%20From%20PDF"
      },
      {
        category: "Integrations",
        library: "AWS Textract",
        name: "Extract Text From Image",
        href: "/prefix.AwsTextract#Extract%20Text%20From%20Image"
      },
      {
        category: "Integrations",
        library: "Veryfi",
        name: "Process Document",
        href: "/prefix.Veryfi#Process%20Document"
      },
    ]
  },
  {
    name: "Editing a spreadsheet",
    description: "Creating Google Sheets & Excel documents, adding, updating, and deleting rows.",
    actions: [
      {
        category: "Data Transformation",
        library: "Excel",
        name: "Write Excel Row",
        href: "/prefix.Excel#Write%20Excel%20Row"
      },
      {
        category: "Data Transformation",
        library: "Excel",
        name: "Read Excel Row",
        href: "/prefix.Excel#Read%20Excel%20Row"
      },
      {
        category: "Integrations",
        library: "Google Sheets",
        name: "Append Row",
        href: "/prefix.GoogleSheets#Append%20Row"
      },
    ]
  },
  {
    name: "Pausing automations",
    description: "Pause an automation to input passwords or review important data, then continue.",
    actions: [
      {
        category: "Interactive",
        library: "Dialogs",
        name: "Get Value From User",
        href: "/prefix.Dialogs#Get%20Value%20From%20User"
      },
      {
        category: "Interactive",
        library: "Dialogs",
        name: "Get And Update Values From User",
        href: "/prefix.Dialogs#Get%20And%20Update%20Values%20From%20User"
      },
      {
        category: "Interactive",
        library: "Dialogs",
        name: "Pause Execution",
        href: "/prefix.Dialogs#Pause%20Execution"
      },
    ]
  },
]

export default {
  name: "Patterns",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronLeftIcon,
    ExternalLinkIcon,
  },
  methods: {
    getCategoryMetadata(title) {
      return meta.find((item) => item.title === title);
    },
    goToAction(action) {
      window.location.href = action.href;
    }
  },
  data() {
    return {
      patterns
    }
  }
}
</script>