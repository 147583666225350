class Events {
  constructor() {
    this.events = {};
  }
  /**
   * @param {String} id 
   * Event id to listen for. Recommend kebab-case.
   * @param {Function} fn 
   * Callback function to be called when the event is emitted.
   */
  on(id, fn) {
    this.events[id] = this.events[id] || [];
    this.events[id].push(fn);
  }
  /**
   * @param {String} id 
   * Event id to emit. Recommend kebab-case.
   * Fails quietly if no event is registered for the id.
   * @param args 
   * Argument to be passed to the callback function.
   * (Despite the name, does not support multiple arguments)
   */
  emit(id, args) {
    if (!this.events[id]) return;
    this.events[id].forEach(function (fn) {
      fn(args);
    });
  }
}

export const EventBus = new Events();
