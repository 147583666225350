<template>
    <div class="pb-2">
        <div @click="collapsed = !collapsed" class="cursor-pointer group">
            <div class="pl-5 pt-2 pb-2 inline-flex">
                <div class="h-full align-middle self-center mr-0">
                    <img :src="findCategoryIcon(label)" :class="['w-12 h-auto -ml-2 duration-200 group-hover:opacity-90', (collapsed ? 'opacity-50' : '')]" />
                </div>
                <div>
                    <div :class="['duration-200 font-semibold text-md brightness-80 group-hover:brightness-100 group-hover:opacity-90 cursor-pointer select-none', (collapsed ? 'opacity-50' : '')]"
                        >{{ label }}</div>
                    <div :class="['font-normal text-xs max-w-sm text-neutral-400 brightness-80 group-hover:brightness-100 group-hover:opacity-90 cursor-pointer select-none duration-200', (collapsed ? 'opacity-50' : '')]">
                        {{ categoryDescriptions[label] }}
                    </div>
                </div>
            </div>
        </div>
        <Library v-if="!collapsed" v-for="[libTitle, libData] in Object.entries(libraries).sort(([a,], [b,]) => a.localeCompare(b))"
            :label="libTitle" :id="libData.id" :keywords="libData.keywords" />
    </div>
</template>

<script>
import Library from './Category/Library.vue'

import meta from './meta';

export default {
    name: "Category",
    props: {
        label: String,
        libraries: Object,
    },
    components: {
        Library
    },
    methods: {
        findCategoryIcon(title) {
            return meta.find(metadata => metadata.title === title).icon;
        }
    },
    data() {
        return {
            collapsed: false,
            categoryDescriptions: {
                'Common': 'A set of standard operations for building automations in Prefix.',
                'Robotic': 'Mimic human actions (clicking, typing, navigation, etc.) on any application.',
                'Data Transformation': 'Transform data from one format to another.',
                'Integrations': 'Connect directly to other applications and services.',
                'Interactive': 'Display dialogs and interfaces as automations are running.',
                'Artificial Intelligence': 'Perform machine learning tasks with minimal configuration.',
            }
        }
    },
}
</script>