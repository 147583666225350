<template>
    <main class="bg-white text-px-grey col-span-7 md:col-span-5 overflow-y-scroll h-auto">
        <div class="max-w-4xl mx-auto sm:px-12 px-4 py-12">
            <h1 class="text-4xl font-medium mb-3">Documentation</h1>
            <p class="text-xl">Explore actions in Prefix and find the ones relevant to your work.</p>
            <!-- TODO: Intro page or table of contents or something? -->
            <div class="mt-8 py-10 px-8 rounded-md md:grid-cols-2 grid grid-cols-1 bg-neutral-100 border border-neutral-300 md:hidden visible">
                <div>
                    <h1 class="text-2xl font-medium mb-3">By Category</h1>
                    <p class="text-lg">Prefix has six major categories of actions for automating different workflows.</p>
                    <button @click="openSidebar" class="mt-4 bg-neutral-50 hover:bg-white hover:shadow-none duration-200 border border-neutral-200 shadow-sm text-md rounded-md px-4 py-2.5 text-neutral-800">Browse everything &nbsp; →</button>
                </div>
                <div class="mt-8 md:ml-4 md:mt-0 h-full w-full flex-row justify-start">
                    <div class="grid grid-cols-3 grid-rows-2 align-middle justify-center mx-auto max-w-sm md:mr-4 ">
                        <div v-for="library in libraries" :key="library" :style="'background-color: ' + findCategoryColor(library)" class="h-auto w-auto opacity-90 aspect-square m-2 p-1 inline-flex justify-center self-center rounded-md mb-2 border-neutral-300 shadow drop-shadow-xl ">
                            <img :src="findCategoryIcon(library)" :class="['text-neutral-800 z-0 self-center align-middle duration-200 group-hover:opacity-90 mix-blend-overlay']" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-10 rounded-md grid grid-cols-1">
                <div class="h-full w-full flex-row mb-10 md:mb-16">
                    <img :src="'/images/action-slice.png'" class="rounded-md border-neutral-300 border" />
                </div>
                <div class="mb-10">
                    <h1 class="text-2xl font-medium mb-3">Popular Workflows</h1>
                    <p class="text-lg">Discover frequently used actions for the specific tasks you're looking to automate.</p>
                    <Patterns />
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue';
import Patterns from './Patterns.vue';

import meta from './Sidebar/meta';

export default {
    name: "NotFound",
    components: {
        Breadcrumb,
        Patterns
    },
    methods: {
        openSidebar() {
            document.getElementById("sidebarToggle").click();
        },
        findCategoryColor(title) {
            return meta.find(m => m.title === title).color;
        },
        findCategoryIcon(title) {
            return meta.find(metadata => metadata.title === title).icon;
        }
    },
    data() {
        return {
            libraries: ['Common', 'Robotic', 'Data Transformation', 'Integrations', 'Interactive', 'Artificial Intelligence']
        }
    }
}
</script>