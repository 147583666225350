<template>
    <div class="self-center bg-neutral-500 text-white px-2 py-0.5 mb-px rounded-sm opacity-70 hover:opacity-50 cursor-pointer font-extralight text-xs shadow-sm shadow-px-/ active:shadow-none select-none">
        <slot />
    </div>
</template>

<script>
export default {
    name: "Key"
}
</script>