<template>
    <div>
        <div v-if="shown" @click="hide" class="w-full h-full absolute bg-px-grey top-0 left-0 opacity-75">
        </div>
        <div v-if="shown"
            class="inset-0 z-50 md:w-3/4 md:h-2/3 rounded-none absolute md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 flex flex-col md:rounded-md drop-shadow-xl">
            <div class="px-3 w-full text-px-grey bg-white md:rounded-t-md flex flex-row">
                <svg width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="self-center mr-3">
                    <path d="m19 19-3.5-3.5"></path>
                    <circle cx="11" cy="11" r="6"></circle>
                </svg>
                <input v-model="search" id="search" ref="search" type="search" autocomplete="off" placeholder="Search..."
                    class="h-full grow py-4 bg-transparent outline-none">
                <Key class="py-1.5" @click="hide">Esc</Key>
            </div>
            <Hits :hits="hits" @click="hide" />
        </div>
    </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { EventBus } from '@/events.js';
import Key from './Key.vue';
import Hits from './Search/Hits.vue';

let timer;
function debounce(fn, timeout) {
    clearTimeout(timer);
    timer = setTimeout(fn, timeout);
}

export default {
    name: "Search",
    components: {
        Key,
        Hits
    },
    data() {
        return {
            index: null,
            shown: false,
            search: null,
            hits: []
        };
    },
    methods: {
        hide() {
            this.shown = false;
        }
    },
    created() {
        // Initialize Algolia
        let searchClient = algoliasearch(
            'K7SIEAP4Y1',
            '916cecd2dca7559f74a30437694af297'
        );
        this.index = searchClient.initIndex('actions');
        this.search = ""; // Trigger an initial search to put content in hits
        // Esc closes search popup
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.shown = false;
            }
        });
    },
    mounted() {
        EventBus.on("search-button-click", () => {
            this.shown = true;
        });
    },
    watch: {
        search() {
            // TODO: tag filters
            debounce(() => {
                this.index.search(this.search, {
                    filters: 'NOT tags:Low'
                }).then((response) => {
                    this.hits = response.hits;
                });
            }, 300);

        },
        shown() {
            this.$nextTick(() => {
                if (this.shown) {
                    this.$refs.search.select();
                }
            });
        }
    }

}
</script>
<style>
#search::-webkit-search-cancel-button{
    display:none;
}
</style>