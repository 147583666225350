<template>
    <aside class="py-4 pb-10 pr-4 col-span-2 overflow-y-scroll max-h-screen h-auto">
        <!-- TODO: Some sort of loading placeholder -->
        <Category v-for="entry in structureEntries" :label="entry" :libraries="structure[entry]" />
    </aside>
</template>

<script>
import Category from './Sidebar/Category.vue'

export default {
    name: 'Sidebar',
    components: {
        Category
    },
    data() {
        return {
            structureEntries: [],
            structure: {},
        }
    },
    created() {
        // Grab category, library, and keyword labels from server
        fetch("https://px-actions.herokuapp.com/structure", { headers: { "x-api-key": "c3902840-8073-4518-928f-c513391dcd8f" } })
          .then(response => response.json())
          .then(data => {
            this.structure = data;
            this.structureEntries = ['Common', 'Robotic', 'Data Transformation', 'Integrations', 'Interactive', 'Artificial Intelligence'];
          })
          .catch(error => {
            console.error(error);
          });
    },
}
</script>

