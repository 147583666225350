<template>
    <div class="hits overflow-y-auto rounded-b-md h-full w-full bg-neutral-800">
        <router-link :to="getHref(hit)"
            v-for="hit in hits" @click="onClickHit(hit)" class="w-full px-12 py-2 hover:brightness-125 active:brightness-150 bg-neutral-800 block">
            <div class="bg-transparent flex flex-row items-center">
                <span class="whitespace-nowrap">{{ hit.label || hit.id || hit.objectID }}</span>
                <span class="opacity-75 mt-0.5 mx-[1ch] text-sm">{{ hit.parentLabel }}</span>
                <div v-for="tag in hit.tags" class="m-0.5 mt-1 bg-white text-px-grey rounded px-1 opacity-60 text-xs w-min">
                    {{ tag }}
                </div>
            </div>
            <div class="whitespace-nowrap text-ellipsis overflow-hidden mr-2 mt-1 text-xs opacity-60"
                v-html="backticksToCode(hit.shortdoc) || backticksToCode(hit.doc)"></div>
        </router-link>
    </div>
</template>

<script>
import { EventBus } from '@/events.js';

export default {
    name: "Hits",
    props: {
        hits: Array,
    },
    methods: {
        backticksToCode(text) {
            if (!text) return '';
            return text.replace(/```([^`]*)```/g, '<code>$1</code>').replace(/``([^`]*)``/g, '<code>$1</code>').replace(/`([^`]*)`/g, '<code>$1</code>');
        },
        onClickHit(hit) {
            EventBus.emit('search-hit-click', this.getHref(hit));
        },
        getHref(hit) {
            // FIXME: library links are trying to use the label in their requests instead of their id
            // the solution seems obvious (replace hit.label with hit.id at the end of the line below),
            // but implementing it breaks the search and I don't have time to figure out why.
            return hit.parentId ? `${hit.parentId}#${hit.label || hit.id || hit.objectID}` : hit.label
        },
    },
}
</script>
<style>
.hits::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}
</style>