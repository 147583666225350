<template>
    <button class="self-center focus:outline-none focus:ring-none bg-transparent flex flex-row text-px-neutral pr-8 my-3.5 w-64 brightness-75 hover:brightness-100"
        @click="onClickSearchButton">
        <SearchIcon class="w-5 h-full align-middle self-center mr-2.5 text-neutral-200" />
        <span style="font-size: 0.9rem; text-rendering: geometricPrecision" class="text-neutral-300 self-center">Search all actions...</span>
        <Key class="ml-auto">/</Key>
    </button>
</template>

<script>
import { EventBus } from '@/events.js'
import Key from '@/components/Key.vue'

import { SearchIcon } from '@heroicons/vue/solid'

export default {
    name: "SearchButton",
    components: {
        Key,
        SearchIcon
    },
    methods: {
        onClickSearchButton() {
            EventBus.emit("search-button-click");
        }
    },
    created() {
        // cmd+k or ctrl+k opens search popup
        document.addEventListener("keydown", (e) => {
            if (e.key === "/") {
                e.preventDefault();
                EventBus.emit("search-button-click");
            }
        });
    }
}
</script>