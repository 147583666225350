<template>
    <div>
        <router-link :to="id" :class="{ 'border-l-2 !brightness-100': selected }" @click="toggleCollapsed"
            class="pl-10 py-1 brightness-75 block hover:brightness-90 cursor-pointer select-none border-px-orange ">
            <ChevronDownIcon v-if="!collapsed" class="text-neutral-400 h-full self-center w-3.5 mr-1 mt-px inline" />
            <ChevronRightIcon v-if="collapsed" class="text-neutral-400 h-full self-center w-3.5 mr-1 mt-px inline" />
            {{ label }}
        </router-link>
        <router-link :to="`${id}#${kwd}`" v-if="!collapsed" v-for="kwd of keywords.sort()"
            class="pl-16 py-1 block text-sm brightness-75 hover:brightness-90 cursor-pointer select-none"
            :class="{ '!brightness-100': kwd === $route.hash.substring(1) }">
            {{ kwd }}
        </router-link>
    </div>
</template>

<script>
import { EventBus } from '@/events.js';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/outline';

export default {
    name: "Library",
    components: {
        ChevronDownIcon,
        ChevronRightIcon
    },
    props: {
        label: String,
        id: String,
        keywords: Array
    },
    data() {
        return {
            collapsed: true
        }
    },
    mounted() {
        // TODO: uncollapse on initial page load if necessary

        EventBus.on('search-hit-click', (href) => {
            
            // Uncollapse parents of keyword that the clicked hit led to
            let targetKeyword = href.split('#')[1];
            if (this.keywords.includes(targetKeyword)) {
                this.collapsed = false;

                // Scroll sidebar to target
                let keyword = document.querySelector(`a[href='/${href}']`);
                if (keyword) {
                    this.$nextTick(() => {
                        // FIXME: Just... Not working. Sigh.
                        keyword.scrollIntoView();
                    });
                }
            }

            // TODO: Uncollapse category
        });
    },
    computed: {
        selected() {
            return this.id === this.$route.params.library;
        }
    },
    methods: {
        // Clicking the library while it's selected will collapse/expand it, but switching to it will only expand it, never collapse it.
        toggleCollapsed() {
            if (!this.selected) {
                this.collapsed = false;
            } else {
                this.collapsed = !this.collapsed;
            }
        }
    }
}
</script>