<template>
  <div class="grid grid-cols-7 grid-rows-[auto_1fr] text-white bg-px-grey h-screen">
    <Header />
    <Sidebar class="hidden md:block" />
    <router-view :libraries="libraries"></router-view>
    <Search />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import Search from './components/Search.vue'

export default {
  name: 'App',
  components: {
    Header,
    Sidebar,
    Search
  },
  created() {
    // Grab all actions data from server
    fetch("https://px-actions.herokuapp.com/fetch", { headers: { "x-api-key": "c3902840-8073-4518-928f-c513391dcd8f" } })
      .then(response => response.json())
      .then(data => {
        this.libraries = data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  data() {
    return {
      libraries: {},
    }
  },
}
</script>
<style>
body, button {
  text-rendering: geometricprecision !important;
}
header {
  position: relative;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 24%);
  
}
.Papercups-chatWindowContainer {
  border: 1px solid #1a1a1a;
  border-radius: 6px !important;
  background: #1a1a1a !important;
}
.Papercups-toggleButton {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  border: 1px solid #5a5a5a !important;
  border-radius: 4px !important;
}
</style>