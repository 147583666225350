<template>
    <header class="h-max col-span-7 border-b border-neutral-700 z-10 sticky top-0 bg-neutral-800">
        <div class="justify-between flex flex-row py-1 px-6">
            <span @click="onClickHeaderText" class="self-center text-lg cursor-pointer flex items-center gap-2">
                <!-- Prefix Editor Logo -->
                <img class="mt-px" width="144" src="/images/actions-logo.png"/>
            </span>
            <!-- TODO: Tag based navigation -->
            <div class="flex flex-row h-full align-middle py-3 md:py-0">
                <SearchButton class="hidden md:flex" />
                <a href="https://www.prefix.app" style="font-size: 0.9rem;" class="text-neutral-200 text-sm self-center mr-6 flex">Website <ExternalLinkIcon class="text-neutral-400 h-full self-center w-3.5 ml-1 mt-px" /></a>
                <a href="https://manual.prefix.app" style="font-size: 0.9rem;" class="text-neutral-200 text-sm self-center flex">Manual <ExternalLinkIcon class="text-neutral-400 h-full self-center w-3.5 ml-1 mt-px" /></a>
            </div>
        </div>
        <div class="border-y border-neutral-700 md:hidden">
            <Breadcrumb />
        </div>
    </header>
</template>

<script>
import SearchButton from './Header/SearchButton.vue'
import Breadcrumb from './Breadcrumb.vue'
import { ExternalLinkIcon } from '@heroicons/vue/outline';

export default {
    name: "Header",
    components: {
        SearchButton,
        ExternalLinkIcon,
        Breadcrumb
    },
    methods: {
        onClickHeaderText() {
            this.$router.push("/");
        }
    }
}
</script>