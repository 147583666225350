import { createApp } from "vue";
import App from "./App.vue";
import "./styles.css";
import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import Content from "./components/Content.vue";
import NotFound from "./components/NotFound.vue";
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/:library", component: Content },
    { path: "/", component: NotFound }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      let anchor = document.getElementById(to.hash.substring(1));
      if (anchor) {
        anchor.scrollIntoView();
      }
    } else if (savedPosition) return savedPosition;
  }
});

createApp(App).use(router).mount("#app");
